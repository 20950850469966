import React from "react"
import {graphql } from "gatsby"

import Layout from "../components/layout"


import SEO from "../components/seo"

const software = [
  ["https://www.gatsbyjs.org", "GatsbyJS"],
  ["https://reactjs.org", "ReactJS"],
  ["https://www.djangoproject.com/", "Django"],
  ["https://www.django-rest-framework.org/", "Django Rest Framework"],
  ["https://www.python.org/", "Python"],
  ["https://www.postgresql.org/", "PostgreSQL"],
  ["https://ubuntu.com/", "Ubuntu"],
  ["https://httpd.apache.org/","Apache"],
  ["https://www.reportlab.com/", "Reportlab"],
  ["https://www.chartjs.org/", "ChartJS"]
]

const IndexPage = (props) => (
  <Layout>
    <SEO title="About"/>
    <div className={"about"}>
      <p>This website has been built by Thomas Duncan, managing director of HDA Services Limited. This site and others
        used by the company have been built using the following open source software:</p>
      <ul>
        {software.map((s)=>
          <li key={s[1]}><a target="_blank" rel="noreferrer" href={s[0]} className={'hover-link'}>{s[1]}</a></li>
        )}
      </ul>
      <p>This has been a journey of discovery for me and a learning process as
        much as anything else. It would not have been possible without the help of
        many users of help sites, particularly <a target="_blank" rel="noreferrer" href='https://www.stackoverflow.com' className={'hover-link has-text-weight-bold'}>stackoverflow</a>. Events like
        NHS hack days are a vital jump off point for intrigued amateurs to see new tech, meet people who know what they
        are talking about and germinate ideas.
      </p>
      <p>

      </p>


    </div>


    <div className={"footer"}>
      <div>HDA Services Limited is registered at Companies House (Registration Number 11085685)</div>


    </div>

  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
query {
cdiaryOne: file(relativePath: { eq: "cdiary-diary.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
cdiaryTwo: file(relativePath: { eq: "cdiary-progress.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
cdiaryThree: file(relativePath: { eq: "cdiary-reports.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackOne: file(relativePath: { eq: "dr-feedback-give-feedback-desktop.png" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackTwo: file(relativePath: { eq: "dr-feedback-give-feedback-mobile.png" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}
feedbackThree: file(relativePath: { eq: "dr-feedback-show-feedback.jpg" }) {
childImageSharp {
fluid(maxWidth: 1000) {
...GatsbyImageSharpFluid
}
}
}


}
`

